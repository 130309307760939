import { faAngleDoubleRight, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import React, { Suspense } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
const PopEnquiry = React.lazy(() => import("../PopEnquiry/PopEnquiry"));

const Footer = () => {
  const [pop, setPop] = useState(false);

  return (
    <>
      <footer className="footer">
        <div className="footer-1">
          <div className="links">
            <div className="heading">
              <h1>USEFUL LINKS</h1>
            </div>
            <div className="content">
              <ul>
                <li>
                  <Link to={"/services"}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} /> Services
                  </Link>
                </li>
                <li>
                  <Link to={"/special-deals"}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} /> Specials
                  </Link>
                </li>
                <li>
                  <Link to={"/gallery"}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} /> Gallery
                  </Link>
                </li>
                <li>
                  <Link to={"/why-us"}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} /> Why Us?
                  </Link>
                </li>
                <li>
                  <Link to={"/sitemap"}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} /> Sitemap
                  </Link>
                </li>
                <li>
                  <Link to={"/contact-us"}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} /> Contact Us
                  </Link>
                </li>
                <li>
                  <Link to={"/blog"}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} /> Blog
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="service-off">
            <div className="heading">
              <h1>SERVICES WE OFFER</h1>
            </div>
            <div className="content">
              <ul>
                <li>
                  <Link to={"/services/carpet-cleaning-melbourne"}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} /> Carpet
                    Cleaning
                  </Link>
                </li>
                <li>
                  <Link to={"/services/rug-cleaning-melbourne"}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} /> Rug Cleaning
                  </Link>
                </li>
                <li>
                  <Link to={"/services/upholstery-cleaning-melbourne"}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} /> Upholstery
                    Cleaning
                  </Link>
                </li>
                <li>
                  <Link to={"/services/ndis-house-cleaning-australia"}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} /> NDIS House
                    Cleaning
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="contact-info">
            <div className="info-box">
              <img
                src={require("../../../Assets/Images/Icons/footer-location.webp")}
                alt=""
              />
              <span>Melbourne Australia</span>
            </div>
            <a href={"tel:1300 360 274"}>
              <div className="info-box">
                <img
                  src={require("../../../Assets/Images/Icons/footer-phone.webp")}
                  alt=""
                />
                <span>1300 360 274</span>
              </div>
            </a>
            <a href={"mailto:info@purenbrightcleaning.com.au"}>
              <div className="info-box">
                <img
                  src={require("../../../Assets/Images/Icons/footer-mail.webp")}
                  alt=""
                />
                <span>info@purenbrightcleaning.com.au</span>
              </div>
            </a>
          </div>
        </div>

        <div className="footer-2">
          <p>
            Copyright © Pure N Bright Carpet Cleaning 2025. All rights reserved.
          </p>
          <div className="footer-socials">
          <Link target={"_blank"} to={"https://www.instagram.com/purenbrightclean/reels/"}>
            <FontAwesomeIcon icon={faInstagram} />
          </Link>
          <Link target={"_blank"} to={"https://wa.me/61490255640"}>
            <FontAwesomeIcon icon={faWhatsapp} />
          </Link>
          </div>

        </div>

        <div className="whatsapp-button">
          <Link target={"_blank"} to={"https://wa.me/61490255640"}>
            <FontAwesomeIcon icon={faWhatsapp} />
          </Link>
        </div>
      </footer>
      <div className="enquire-fix-wrapper">
        <button onClick={() => setPop(true)} className="enquire-fix">
          Quick Enquiry
        </button>
      </div>{" "}
      {pop ? (
        <Suspense fallback={<p>Loading...</p>}>
          <PopEnquiry pop={pop} setPop={setPop} />
        </Suspense>
      ) : null}
      <div className="Appointment-fixed">
        <Link to={"/quote"}>Book an Appointment</Link>
      </div>
    </>
  );
};

export default Footer;
